const APP = {
	// сет брейкпоинтов для js
	mediaBreakpoint: {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1290
	},

	init: function() {
		APP.svgIcons()
		APP.objectFitFallback(document.querySelectorAll('[data-object-fit]'));

		APP.cateringSlider();
		APP.clientsSlider();
		APP.menuSlider();
		APP.modalGallery();
	},

	cateringSlider: function () {
		$('.catering-slider .slider').slick({
			dots: true,
		});
	},

	clientsSlider: function () {
		$('.b-clients__slider').slick({
			dots: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows: false,
			responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}]

		});
	},

	modalGallery: function() {
		const template = '<div class="modal-gallery"><span class="arcticmodal-close modal-gallery__close">×</span><div class="slider modal-gallery__slider"><div class="modal-gallery__slide" data-for="data-for"><div class="responsive-img modal-gallery__img-wrap"><img alt="" data-object-fit="scale-down"></div></div></div><div class="modal-gallery__footer"><div class="wrap modal-gallery__footer-inner"><div class="modal-gallery__title"></div><div class="modal-gallery__paging"></div></div></div></div>';

		const init = function () {
			$(document).on('click', '.js-gallery-item', function(e) {
				e.preventDefault();
				const itemsArr = getItems(this)
				const index = $( itemsArr ).index( this )
				const dataArr = getDataArr( itemsArr )
				const content = parseTemplate(template, dataArr)
				openModal( content, index )
			})
		};

		const createSlider = function($elem) {
			const $slider = $elem
			$slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				let i = (currentSlide ? currentSlide : 0) + 1
				const $gallery = slick.$slider.closest('.modal-gallery')
				const str = `${i} / ${slick.slideCount}`;
				const title = $('.slick-current img', $gallery).attr('data-title');
				$gallery.find('.modal-gallery__paging').text(str)
				$gallery.find('.modal-gallery__title').text(title)
			}).slick({
				arrows: true,
				dots: false,
				fade: true,
				speed: 200,
				loop: true,
				lazyLoad: 'ondemand',
			});
			$slider.on('lazyLoaded', function(event, slick, image, imageSource){
				const imagesArr = $("[data-object-fit]", slick.$slider).toArray();
				APP.objectFitFallback( imagesArr );
			})

			return $slider
		}

		const getItems = function (elem) {
			const $gallery = $(elem).closest('.js-gallery')
			const items = []
			$gallery.find('.js-gallery-item').each(function(i, item) {
				const notCloned = $(item).closest('.slick-cloned').length == 0
				if (notCloned) {
					items.push(item)
				}
			});
			return items
		};

		const getDataArr = ( nodeArr ) => {
			const dataArr = []
			$(nodeArr).each(function(i, item) {
				dataArr.push({
					src: item.getAttribute('href'),
					title: item.getAttribute('data-title'),
				})
			});

			return dataArr
		}

		const openModal = ( content, index ) => {
			$.arcticmodal({
				content: $(content),
				overlay: {
					css: {
						backgroundColor: '#000',
						opacity: 0.7
					}
				},
				afterOpen: function(data) {
					const $popup = $(data.body[0]);
					const $elem = $('.modal-gallery__slider', $popup)
					const $slider = createSlider( $elem )
					$slider.slick('slickGoTo', index);
					document.body.style.marginRight = 0;
				},
				afterClose: function() {
					document.body.removeAttribute('style')
				}
			});
		}

		const createElementFromHTML = (htmlString) => {
			var div = document.createElement('div');
			div.innerHTML = htmlString.trim();

			return div.firstChild;
		}

		const getString = (function() {
			var DIV = document.createElement("div");

			if ('outerHTML' in DIV)
				return function(node) {
					return node.outerHTML;
				};

			return function(node) {
				var div = DIV.cloneNode();
				div.appendChild(node.cloneNode(true));
				return div.innerHTML;
			};
		})();

		function parseTemplate (string, array) {
			const template = createElementFromHTML(string)
			const itemTemplate = template.querySelector('[data-for]')
			const list = itemTemplate.parentNode

			itemTemplate.removeAttribute('data-for')
			list.removeChild(itemTemplate);

			for (var i = 0; i < array.length; i++) {
				const data = array[i]
				let itemStr = getString(itemTemplate)
				// itemStr = itemStr.replace('{{ src }}', data.src)

				const renderedItem = createElementFromHTML(itemStr);
				const img = renderedItem.querySelector('img')
				img.setAttribute('data-lazy', data.src)
				img.setAttribute('data-title', data.title)
				list.appendChild(renderedItem);
			}
			return template
		}

		init();
	},


	menuSlider: function () {
		const $slider = $('.menu-list__slider')
		$slider.slick({
			dots: true,
			slidesToShow: 3,
			slidesToScroll: 2,
			arrows: false,
			lazyLoad: 'progressive',
			responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});

		$slider.on('lazyLoaded', function(event, slick, image, imageSource){
			const imagesArr = $("[data-object-fit]", slick.$slider).toArray();
			APP.objectFitFallback( imagesArr );
		})
	},

	svgIcons: function () {
		const container = document.querySelector('[data-svg-path]')
		if (!container) { return }
			const path = container.getAttribute('data-svg-path')
		const xhr = new XMLHttpRequest()
		xhr.onload = function() {
			container.innerHTML = this.responseText
		}
		xhr.open('get', path, true)
		xhr.send()
	},

	objectFitFallback: function(selector) {
		if ('objectFit' in document.documentElement.style === false) {
			for (var i = 0; i < selector.length; i++) {
				const that = selector[i]
				const imgUrl = that.getAttribute('src');
				let fitStyle = that.getAttribute('data-object-fit') === 'cover' ? 'cover' : 'contain';

				const parent = that.parentElement
				if (imgUrl) {
					parent.style.backgroundImage = 'url(' + imgUrl + ')'
					parent.style.backgroundSize = fitStyle
					parent.classList.add('fit-img')
				}
			};
		}
	},

	getScrollbarSize: function() {
		let scrollbarSize;
		if (scrollbarSize === undefined) {
			const scrollDiv = document.createElement('div');
			scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
			document.body.appendChild(scrollDiv);
			scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
			document.body.removeChild(scrollDiv);
		}
		return scrollbarSize;
	},
};

document.addEventListener('DOMContentLoaded', function() {
	APP.init();
});
